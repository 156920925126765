
import { defineComponent } from "@vue/runtime-core";
import { UserProfileSocial } from "@toppick/common/build/interfaces";
import {
  validateFacebookUrl,
  validateInstagramUrl,
  validateTwitterUrl,
  validateYoutubeUrl,
} from "@toppick/common/build/validators";
import { updateUserSocial } from "@toppick/common/build/api/user";
import { getAuthToken } from "@/utils/auth";
export function validateBioMin(input: string): boolean {
  if (!input) {
    return true;
  }
  return input.length > 80;
}

export function validateBioMax(input: string): boolean {
  return input.length < 500;
}

export function validateBio(input: string): boolean {
  return validateBioMin(input) && validateBioMax(input);
}

export default defineComponent({
  props: {
    facebook_url: String,
    twitter_url: String,
    instagram_url: String,
    youtube_url: String,
    bio: String,
  },
  data() {
    return {
      isModified: false,
      isLoading: false,
      errorMessages: [] as string[],
      successMessages: [] as string[],
      success: "",
      error: "",
      userData: {
        facebook_url: this.facebook_url || "",
        twitter_url: this.twitter_url || "",
        instagram_url: this.instagram_url || "",
        youtube_url: this.youtube_url || "",
        bio: this.bio || "",
      } as UserProfileSocial,
    };
  },

  methods: {
    onChange: async function () {
      this.isModified = true;
    },

    async onSubmit() {
      this.error = "";
      this.isLoading = true;
      try {
        await updateUserSocial(
          await getAuthToken(),
          this.$store.getters.getUserID,
          this.userData
        );
        this.success = "Success";
        this.isModified = false;
        setTimeout(() => {
          this.success = "";
        }, 2000);
      } catch (error) {
        this.error = "";
        console.log(error);
      }
      this.isLoading = false;
    },

    handleInputError(inputName: string): boolean {
      return this.errorMessages.some((error) =>
        error.toLowerCase().includes(inputName)
      );
    },
    handleInputSuccess(inputName: string): boolean {
      return this.successMessages.some((error) =>
        error.toLowerCase().includes(inputName)
      );
    },
  },

  computed: {
    isSubmittable(): boolean {
      if (!this.isModified) return false;
      return this.errorMessages.length === 0;
    },
  },

  watch: {
    "userData.facebook_url": async function () {
      const error = "Invalid Facebook Link";
      const success = "Valid Facebook Link";
      this.errorMessages = this.errorMessages.filter((err) => err !== error);
      this.successMessages = this.successMessages.filter(
        (succ) => succ !== success
      );
      if (!this.userData.facebook_url) {
        return;
      }
      if (!validateFacebookUrl(this.userData.facebook_url)) {
        this.errorMessages.push(error);
      } else {
        this.successMessages.push(success);
      }
    },

    "userData.instagram_url": async function () {
      const error = "Invalid Instagram Link";
      const success = "Valid Instagram Link";
      this.errorMessages = this.errorMessages.filter((err) => err !== error);
      this.successMessages = this.successMessages.filter(
        (succ) => succ !== success
      );
      if (!this.userData.instagram_url) {
        return;
      }
      if (!validateInstagramUrl(this.userData.instagram_url)) {
        this.errorMessages.push(error);
      } else {
        this.successMessages.push(success);
      }
    },

    "userData.twitter_url": async function () {
      const error = "Invalid Twitter Link";
      const success = "Valid Twitter Link";
      this.errorMessages = this.errorMessages.filter((err) => err !== error);
      this.successMessages = this.successMessages.filter(
        (succ) => succ !== success
      );
      if (!this.userData.twitter_url) {
        return;
      }
      if (!validateTwitterUrl(this.userData.twitter_url)) {
        this.errorMessages.push(error);
      } else {
        this.successMessages.push(success);
      }
    },

    "userData.youtube_url": async function () {
      const error = "Invalid Youtube Link";
      const success = "Valid Youtube Link";
      this.errorMessages = this.errorMessages.filter((err) => err !== error);
      this.successMessages = this.successMessages.filter(
        (succ) => succ !== success
      );
      if (!this.userData.youtube_url) {
        return;
      }
      if (!validateYoutubeUrl(this.userData.youtube_url)) {
        this.errorMessages.push(error);
      } else {
        this.successMessages.push(success);
      }
    },

    "userData.bio": async function () {
      const errorMin = "Bio too short";
      const errorMax = "Bio too long";
      const success = "Valid Bio";
      this.errorMessages = this.errorMessages.filter(
        (err) => err !== errorMin && err !== errorMax
      );
      this.successMessages = this.successMessages.filter(
        (succ) => succ !== success
      );
      if (!this.userData.bio) {
        return;
      }
      if (!validateBioMin(this.userData.bio)) {
        this.errorMessages.push(errorMin);
      } else if (!validateBioMax(this.userData.bio)) {
        this.errorMessages.push(errorMax);
      } else {
        this.successMessages.push(success);
      }
    },
  },
});
