
import { ProfilePageTab } from "@/interfaces/ui";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import UserBadge from "@/components/ui/badges/UserBadge.vue";
import { updateProfileImage } from "@toppick/common/build/api/user";
import { getInitials } from "@/utils/ui";
import TabButtonProfile from "./TabButtonProfile.vue";
import { resizeImage } from "@/utils/upload";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  emits: ["onChangeTab"],
  components: { UserBadge, TabButtonProfile },
  props: {
    currentTab: {
      type: String as PropType<ProfilePageTab>,
      required: true,
    },
  },
  data() {
    return {
      ProfilePageTab,
      uploading: false,
      file: null,
      userPhoto: this.$store.getters.getUserPhoto,
    };
  },

  methods: {
    triggerImgInput() {
      const elem = this.$refs.fileUploader;
      if (elem) (elem as HTMLFormElement).click();
    },

    async removeProfileImg() {
      try {
        await updateProfileImage(
          await getAuthToken(),
          this.$store.getters.getUserID,
          null
        );
      } catch (error) {
        console.log(error);
      }
      this.userPhoto = "";
    },

    async getFile(event) {
      this.uploading = true;
      try {
        const file = event.target.files[0];
        if (!file) {
          throw new Error("Image missing");
        }

        const resizedImage = await resizeImage({
          maxSize: 200,
          file,
        });
        const storageRef = firebase
          .storage()
          .ref("profile_pictures/" + this.$store.getters.getUserID);
        await storageRef.put(resizedImage);
        const url = await storageRef.getDownloadURL();
        await updateProfileImage(
          await getAuthToken(),
          this.$store.getters.getUserID,
          url
        );
        this.userPhoto = url;
      } catch (error) {
        console.log(error);
      }
      this.uploading = false;
    },

    onChangeTab(tab: ProfilePageTab) {
      this.$emit("onChangeTab", tab);
    },
  },

  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
    initials() {
      return getInitials(this.$store.getters.getUsername);
    },
  },
});
