
import { defineComponent } from "@vue/runtime-core";
import Loader from "@/components/ui/Loader.vue";
import { ProfilePageTab } from "@/interfaces/ui";
import ProfileInfo from "./sections/ProfileInfo.vue";
import SideMenuProfile from "@/components/profile/SideMenuProfile.vue";
import ProfileSocial from "./sections/ProfileSocial.vue";
import { User } from "@toppick/common/build/interfaces";
import ProfileInterests from "./sections/ProfileInterests.vue";
import "./styles.css";
import { isValidTab } from "@/utils/ui";
import { getUserDetails } from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: {
    Loader,
    ProfileInfo,
    SideMenuProfile,
    ProfileSocial,
    ProfileInterests,
  },
  data() {
    return {
      ProfilePageTab,
      isLoading: true,
      userData: null as null | User,
    };
  },
  methods: {
    setTab(tab: ProfilePageTab) {
      this.$router.replace({
        params: { tab },
      });
    },
    async loadData() {
      const userData = await getUserDetails(await getAuthToken(), {
        user_id: this.$store.getters.getUserID,
      });
      this.userData = userData;
      this.userData.user_languages;
    },
  },
  computed: {
    currentTab(): string {
      return this.$route.params.tab as string;
    },
  },

  async mounted() {
    try {
      if (!isValidTab(ProfilePageTab, this.$route.params.tab)) {
        this.setTab(ProfilePageTab.INFO);
      }
      await this.loadData();
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
});
