
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  emits: ["onClick"],
  props: {
    icon_inactive: String,
    icon_active: String,
    title: String,
    active: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
  computed: {
    showIcon() {
      return this.icon_active && this.icon_inactive;
    },
  },
});
