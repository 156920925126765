import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "tablinks",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.active ? _ctx.icon_active : _ctx.icon_inactive,
          alt: "",
          class: _normalizeClass(["mr-2", { active: _ctx.active }])
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createTextVNode(_toDisplayString(_ctx.title), 1)
  ]))
}