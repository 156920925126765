
import { defineComponent } from "@vue/runtime-core";
import { UserRole } from "@toppick/common/build/interfaces";
import { PropType } from "vue";

export default defineComponent({
  props: {
    role: {
      type: String as PropType<UserRole>,
      required: true,
    },
  },
  computed: {
    badgeText() {
      switch (this.role) {
        case UserRole.Admin:
          return "Admin";
        case UserRole.Creator:
          return "Creator";
        default:
          return "Free Account";
      }
    },
    backgroundClass(): string {
      switch (this.role) {
        case UserRole.Admin:
          return "adminBadge";
        case UserRole.Creator:
          return "creatorBadge";
        default:
          return "defaultBadge";
      }
    },
  },
});
